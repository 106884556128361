$header-height: 600px;
$navbar-mobile: 60px;
$navbar-height: 120px;
$navbar-side-padding: 80px;
$page-title: 100px;
$page-title-mobile: calc($page-title * 1.5);
$header-height-small: $navbar-height;
$footer: 60px;
$light-blue:rgba(71, 181, 255, 0.87);
$blue: rgb(0, 111, 238);
$blue-dark: rgb(11, 67, 141);
$green:rgba(35, 173, 22, 0.979);
$dark-green:rgba(143, 245, 143, 0.979);
$orange: rgb(250, 166, 69);
$white: rgba(204, 204, 204, 0.938);
$black: rgb(54, 54, 54);
$gray: rgb(184, 184, 184);
$beige: rgb(199, 190, 168);
$gray-background: rgba(233, 233, 233, 0.486);
$contact-popup: 400px;
$blue-overlay: rgba(17, 17, 17, 0.596);
$overlay: rgba(255, 255, 255, 0.466);
$main-blue: rgba(139, 141, 143, 0.87);
$color: rgba(168, 168, 168, 0.87);
$home-services-width: 70vh;
$header-blue: rgb(48, 135, 192);

html,
body {
  scrollbar-width: none;
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto; 
  margin: 0px!important;
  background-color: white;
  max-width: 100vw;
}

#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.elger-container{
  min-height: 100vh;
  height: auto;

  &.pages{
    min-height: calc(100vh - $page-title - $header-height-small - $footer);
  }
}

  .custom-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(18, 19, 24, 0.945);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    .popup-content {
      position: relative;
      max-width: 600px; 
      max-height: calc(100vh  - 120px); 
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-radius: 8px;
      overflow: hidden;
      z-index: 200;
      transition: all 0.3s ease; 

      @media (max-width: 480px) {
        // max-width: calc(100vh  - 400px); 
        // max-height: calc(100vh  - 370px); 
      }

      .popup-image {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          max-width: 90%;
          max-height: 100%;
          object-fit: contain; 
          transition: transform 0.3s ease;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
        }
      }
      
     

      .popup-close{
        position: fixed;
        width: 100%;
        padding-bottom: 50px;
        top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border: none;
        padding-right: 10px;
        color: rgb(209, 209, 209);

        svg{
          cursor: pointer;
        }
      }
    
      .popup-navigation {
        position: fixed;
        display: flex;
        justify-content: space-between;
        width: 100%;
        top: 50%;


        button {
          background: transparent;
          color: rgb(209, 209, 209);
          border: none;
          cursor: pointer;
          border-radius: 4px;
          width: 40px;
          height: 40px;
          margin-top: -40px;
          display: flex;
          justify-content: center;
          align-items: center;
    
          &:disabled {
            cursor: not-allowed;

            svg{
              color: #3f3f3f!important;
            }
            
          }

          
        }
      }
    }
  } 

  p {
    cursor: default;
    margin: 0;
  }

  header.elger-header {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: $header-height;
    z-index: 600;
    display: flex;

    .overlay {
      z-index: 0;
      position: relative;
      top: 0;
      width: 100%;
      height: $header-height;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
      box-sizing: border-box;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        height: $header-height;
        width: 100%;
        background-color: $overlay; 
        z-index: 200;
      }

      .overlay-image {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.5);

        .overlay-title {
          position: absolute;
          color: white;
          right: 10vw;
          bottom: 30%;
          font-size: 70px;
          font-weight: 700;
          color: rgb(26, 26, 26);
          z-index: 500;
          height: 100px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 5px;

          p {
            margin: 0;
          }

          span {
            color: $header-blue;
            font-size: 30px;

          }

          @media(max-width: 1070px){
            right: max(6vw, 20px);
            font-size: 60px;

            span {
              font-size: 28px;
            }
          }

          @media screen and (max-width: 900px) {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 50px!important;
            width: calc(100vw)!important;;

            p {
              width: calc(100vw - 60px);
              text-wrap: wrap;
              text-align: right;
            }
  
            span {
              font-size: 30px;
              width: calc(100vw - 60px);
              text-wrap: wrap;
              text-align: right;

            }
          }

          @media screen and (max-width: 429px) {
            p {
              font-size: 42px;
              width: calc(90vw);
              padding-right: 7px;
            }
  
            span {
              font-size: 24px;
              width: calc(90vw);
              padding-right: 7px;

            }
          }

          @media screen and (max-width: 295px) {
            p {
              font-size: 30px;
              width: calc(100vw);
              padding-right: 1px;
            }
  
            span {
              font-size: 17px;
              width: calc(100vw);
              padding-right: 1px;

            }
          }
        }
      }

      &.pages{
        display: none;
      }
    }

      .navbar-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: $navbar-height;
        z-index: 1000;
        transition: all 0.3s ease;
        background-color: rgba(0, 0, 0, 0);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .navbar-icon{
          position: fixed;

          &.visible{
            margin: 20px;
            opacity: 1;
            pointer-events: all;
            cursor: pointer;
          }
  
          &.hidden{
            opacity: 0;
            pointer-events: none;
          }
        }
        
        .navbar {
          transition: transform 0.3s ease, opacity 0.3s ease;

          &.hidden {
            transform: translateY(-100%);
            opacity: 0;
          }

          &.visible {
            z-index: 1000;
            width: 100%;
            height: $navbar-height;
            padding: 10px max(8vw, 60px);
            background-color: transparent;
            transform: translateY(0);
            opacity: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }

            .navbar-logo p {
              font-size: 46px;
              font-weight: bold;
              color: rgb(26, 26, 26);
            }
  
            .navbar-menu {
              display: flex;
              flex-direction: row;
              list-style: none;
              gap: 20px;

              li{
                display: flex;
                justify-content: center;
              }
  
              li a {
                text-decoration: none;
                color: rgb(48, 48, 48);
                font-size: 18px;
                padding: 5px 10px;
                transition: background-color 0.3s ease;
                display: flex;
                align-items: center;
                font-weight: 600;
  
                &:hover{
                  color: $header-blue;
                }

                &.active {
                  color: $header-blue;
                }
              }
          }
        }

        @media (max-width: 1070px){

          .navbar {
            &.visible {
              padding: 10px max(4vw, 20px);
            }
          }

          .navbar-logo p {
            font-size: 42px!important;
          }

          .navbar-menu {
            gap: 10px!important;
            padding-left: 10px!important;

            li a{
              font-size: 16px!important;;
            }
        }
    }  

    @media (max-width: 850px){

      .navbar {
        &.visible {
          padding: 10px 20px;
        }
      }

      .navbar-logo p {
        // font-size: 38px!important;
      }

      .navbar-menu {
        gap: 2px!important;
        padding-left: 10px!important;
    }
}  

@media (max-width: 806px){

  .navbar-logo p {
    font-size: 37px!important;
  }

  .navbar-menu {
    gap: 1px!important;
    padding-left: 8px!important;
}
}  
  }

.navbar-container-mobile {
  width: 0px;
  height: 0px;

  &.visible{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1000;
  }

  &.hidden{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1000;
  }

  .overlay-name{
    margin-top: 20px;
    margin-left: 40px;
    font-size: 38px;
    font-weight: 700;

    @media (max-width: 350px){
      font-size: 34px!important;
      margin-left: 24px!important;
    }

    @media (max-width: 308px){
      font-size: 28px!important;
      margin-left: 20px!important;
      max-width: calc(50vw)!important;
    }
  }

  .navbar-icon {
    position: fixed;
    right: 0;
    padding: 20px;
    cursor: pointer;
    z-index: 1100;
  
    svg {
      transition: transform 0.3s ease;
  
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  
  .navbar-mobile {
    position: fixed;
    top: 0;
    left: -100%; 
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: left 0.3s ease;

    &::after{
      content: '';
      position: absolute;
      top: 0px;
      background-color: #00000059;
      width: 100vw;
      height: 100vh;
      z-index: -1;

    }

    &.visible {
      left: 0; 
      opacity: 1;
    }
  
    &.hidden {
      left: -100%;
      opacity: 0;
    }
  
    .navbar-logo {
      background-color: rgba(255, 255, 255, 0.466);
      font-size: 44px;
      font-weight: 600;
      color: $black;
      width: 100vw;
      height: 40vh;
      color: #1d1d1d;
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p{
        z-index: 1200;
      }

      span{
        font-size: 34px;
      }

      @media (max-width: 336px){
        font-size: 34px!important;

        span{
          font-size: 24px;
        }

      }

      
    }
  
    .navbar-menu {
      list-style: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0px;
      margin: 0;
      margin-top: 2px;


      li{
        width: 100%;
        background-color: rgba(242, 247, 250, 0.966);
        margin-bottom: 2px;
        height: 55px;
        display: flex;
        align-items: center;
      }
  
      a {
        width: 100%;
        font-size:18px;
        color: black;
        text-decoration: none;
        transition: color 0.3s ease;
        z-index: 1300;
        padding: 16px 10px 16px 30px;

        &.active{
          color: $header-blue;
          font-size: 19px;
          width: inherit!important;
          font-weight: 500;
        }
        
        &:hover {
          color: rgb(224, 39, 70);
        }
      }
    }

    .empty-box{
      width: 100vw;
      height: 40vh;
      background-color: rgba(255, 255, 255, 0.466);
      display: flex;
      justify-content: center;
      align-items: center;

      div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        color: #1d1d1d;
        text-shadow: 0 1px 2px #1d1d1daf;
      }
    }
  }
}
  }

  header.elger-header.pages{
    position: fixed;
    height: $header-height-small;
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;

    @media(max-width: 720px){
      height: 0px;
    }

    .overlay-name{
      display: none
    }

    .overlay{
      display: none;
    } 

    .navbar-container{
      width: 100%;
      background-color: #f8f8f8;
      .navbar-icon{
        display: none;
      }

      .navbar-logo p{
        color: $black;
      }

      .navbar-menu {

        li a {
          color: $gray;

          &:hover,
          &.active {
            color: $black;
          }
        }
    }
    }

  }

  main.elger-page {
    z-index: 0;
    flex: 1;
    width: 100vw;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;
    color: $gray;
    overflow-y: hidden;
    overflow-x: hidden;
    height: auto;

    &.pages{
      position: relative;
      left: 0px;
      height: auto;
      margin-top: $page-title + $header-height-small;

      @media (max-width: 720px) {
        margin-top: $page-title-mobile;
      }


      .page-title {
        position: fixed;
        top: $header-height-small;
        width: 100%;
        left: 0px;
        z-index: 50;
        font-size: 26px;
        font-weight: 500;
        height: $page-title;
        display: flex;
        align-items: center;
        z-index: 20;
        color: white;

        p{
          margin-left: calc(max(8vw, 60px) + 2px);
          z-index: 700;
          color: rgb(46, 46, 46);
          font-weight: 700;
        }

          &::after {
            content: "";
            position: absolute;
            top: 0px;
            height: $page-title;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.623); 
            z-index: -1; 

            @media (max-width: 720px) {
              background-color: rgba(255, 255, 255, 0.623); 
            }
          }

          @media (max-width: 1050px) {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;

            p{
              margin-left: 0px;
              z-index: 700;
            }
          }

          @media (max-width: 720px) {
            height: $page-title-mobile!important;
            font-size: 28px!important;
            top: 0px;
            
            p{
              margin-left: 0px;
              z-index: 700;
              color: rgb(46, 46, 46);
              text-align: center;
            }

            &::after {
              height: $page-title-mobile;
 
            }
          }
      }
    }

    .elger-home {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: auto!important;


      .home-content {
        left: 0px;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;


        .content-slider {
          background-color: rgb(255, 255, 255);
          width: 100%;
          height: 140px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .content-slider {
          width: 100%;
          overflow: hidden; /* Ukrywa elementy poza widocznym obszarem */
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        
        .slider {
          display: flex;
          position: relative;
          width: 100%;
        }
        
        .slider-group {
          display: flex;
          gap: 10px; 
          will-change: transform; 
        }
        
        .slider-card p {
          margin-top: 5px;
          font-size: 14px;
          text-align: center;
        }
        
      
        .slider-card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: auto;
          height: 45px;
          padding: 5px;
        }
        
        .slider-card img {
          min-width: 100px;
          max-width: 130px;
          height: 45px;
        }
        
        .home-content-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 90%;
          text-align: justify;
          padding-top: 20px;
          gap: 40px;
          background-color: $gray-background;

          @media (max-width: 1050px) {
            width: 95%!important;
            gap: 20px!important;
          }

          .services-grid {
            flex: 1;
            display: grid;
            margin: 0;
            padding: 0;
            grid-template-columns: repeat(8, 120px);
            grid-template-rows: repeat(1, 120px);
            gap: 15px;
            display: none;

            .service-card {
              perspective: 1000px;
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);

              .service-card-inner {
                position: relative;
                width: 100%;
                height: 100%;
                transition: transform 0.6s;
                transform-style: preserve-3d;

                &::before {
                  content: "";
                  position: absolute;
                  background-color: #24364d67;
                  top: 0px;
                  left: 0px;
                  height: 101%;
                  width: 101%;
                  z-index: 500;
                  border-radius: 4px;
                }

                .service-card-front,
                .service-card-back {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  backface-visibility: hidden;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 4px;
                  overflow: hidden;
                }

                .service-card-front .service-image {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }

                .service-card-back {
                  color: $blue;
                  fill: $blue;
                  transform: rotateY(180deg);
                  flex-direction: column;
                  background-color: none;
                  border-radius: 4px;
                }

                .service-icon {
                  width: 50px;
                  height: 50px;
                  margin-bottom: 8px;
                }

                .service-card-back p {
                  text-align: center;
                  font-size: 14px;
                  color: $blue;
                }
              }

              &:hover .service-card-inner {
                transform: rotateY(180deg);

                &::before {
                  background-color: #dddddd00;
                }
              }
            }
          }
          
          .info-section {
            width: 100%;

            .info-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              gap: 0px;
              margin: 0;
              padding: 0px 20px;
              text-align: left;

              @media (max-width: 1600px) {
                padding: 0px 5px!important;
              }

              .text-column {
                display: flex;
                gap: 30px;
                flex-direction: column!important;
                gap: 0px!important;
                width: 80%;
                text-align: justify;

                @media (max-width: 1000px) {
                  width: 90%;
  
                }

                h2 {
                  font-size: 20px;
                  margin-bottom: 10px;
                  color: rgb(180, 180, 180);
                  font-weight: 700;
                  text-align: center;
                  width: 100%;
                  text-transform: uppercase;
                }

                p {
                  font-size: 16px;
                  line-height: 1.8;
                  margin-bottom: 20px;
                  color: $black;

                  &.text {
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    font-size: 18px;
                  }

                  &.services {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center!important;
                    position: relative;
                    font-weight: 500;
                    color: $black;

                      span.box{
                        text-align: center;
                        background-color: $blue-overlay;
                        width: calc(100vw);
                        height: 45px;
                        padding: 5px;
                        margin-bottom: 10px;
                        position: relative;

                        span.text{
                          position: absolute;
                          z-index: 200;
                          width: calc(100vw);
                          left: 0px;
                          top: 5px;
                          height: 35px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          color: rgb(235, 235, 235);
                          text-shadow: 0 1px 4px rgb(32, 32, 32);
                          font-size: 20px;
                          font-weight: 500;
                          text-transform: uppercase;
                        }

                        &::after{
                          content: '';
                          width: calc(100vw);
                          left: 0px;
                          top: 0px;
                          height: 45px;
                          background-color: rgba(2, 2, 2, 0.651);
                          position: absolute;
                          z-index: 50;
                        }

                        &:hover{
                          transform: scale(1.02);
                        }
                      }
                    
                  }
                }
              }
            }
          }
        }

        .home-middle{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p.content-title {
            height: 40px;
            width: 150px!important;
            padding: 5px;
            font-weight: 500;
            margin-bottom: -20px;
            color: $main-blue;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 20;
            text-align: center;

            span{
              width: 150px;
              height: 40px;
              background-color: lighten($green, 5%);
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              color: white;
              text-shadow: 0 1px 4px rgba(37, 173, 37, 0.26);

            }
  
            @media (max-width: 1050px) {
              width: 95%!important;
            }
          }
        }

        .home-bottom {
          font-size: 22px;
          font-weight: 600;
          border-radius: 4px;
          width: auto;
          padding: 4px 40px;
          margin-bottom: 20px;
          color: $header-blue;
          display: flex;
          flex-direction: column;
          text-align: center;

          @media (max-width: 1050px) {
            width: 100%!important;
            text-align: center!important;
          }
        }
      }
    }

    
    .elger-contact {
      padding: 0px;
      color: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: calc(100vh - $footer - $header-height-small - $page-title);

      @media (max-width: 1050px) {
        height: calc(100vh - $footer - $navbar-mobile);
      }

      .page-title{
        z-index: 7000;
      }

      .contact-flex {
        height: 100%;
  
        .first-box {
          width: calc(40vw + 60px);
          height: 100%;
          padding: 5px min(8vw, 40px) 5px max(8vw, 80px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: absolute;
          z-index: 500;
          background-color: rgba(255, 255, 255, 0.87);
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
          @media (max-width: 1050px) {
            padding: 5px min(6vw, 60px) 5px max(6vw, 60px);
          }
        }
  
      }
      
      .main-info{
        font-size: max(min(calc(100vw/20), 30px), 22px);
        font-weight: 600;
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 10px 0px 10px 0px;
  
        .contact-data {
          font-weight: 400;
        }
      }
      
      .contact-data {
        padding: 8px 0px;
        font-size: 20px!important;
  
        svg{
          font-size: 20px!important;
          width: 20px!important;
        }
  
        p {
          display: flex;
          align-items: center;
          margin: 5px 0;
          gap: 10px;
        }
  
        a {
          color: $black;
          text-decoration: none;
        }
  
        a:hover {
          text-decoration: underline;
          color: rgba(125, 184, 124, 0.829);;
        }
  
        .title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
        }
      }
  
  
    
      .contact-map {
        grid-column: 1 / -1;
        height: 100%;
        width: calc(100vw);
        border-radius: 2px;
        overflow: hidden;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.973);
  
        .leaflet-popup-tip {
          width: 0px;
          height: 0px;
        }
  
        .leaflet-top.leaflet-left{
          left: calc(100vw - 60px)!important;
  
          .leaflet-control-zoom{
            border: 0px!important;
          }
  
          .leaflet-control-zoom-in, .leaflet-control-zoom-out{
            width: 25px!important;
            height: 25px!important;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px!important;
            font-weight: 500!important;
            background-color: #ffffffc9!important;
  
            span{
              line-height: 15px !important;;
            }
          }
  
        }
        .leaflet-popup-close-button{
          display: none;
        }
  
        .leaflet-popup {
          left: -150px !important;
        }
    
        .leaflet-popup-content-wrapper {
          border-radius: 0px;
          background-color: #ffffffec;
          box-shadow: 2px 4px 10px rgba(68, 68, 90, 0.404)!important;
        }
  
        p{margin: 0px}
    
        .leaflet-popup-content {
          margin: 0 !important;
          width: $contact-popup;
        }
    
        .popup-content {
          color: rgba(5, 26, 66, 0.575);
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          gap: 10px;
          font-size: 16px;
          padding: 15px;
  
          .text{
            display: flex;
            flex-direction: column;
            gap: 5px;
            color: $black;
  
            a{
              color: rgba(125, 184, 124, 0.829);
            }
  
            .address{
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              gap: 20px;
              padding: 0px;
              margin-bottom: 5px;
  
              .title{
                font-weight: 600;
                font-size: 17px;
              }
            }
  
          }
    
        }
      }
  
      @media screen and (max-width: 1050px) {
        .elger-contact {
          height: calc(100vh - $footer - $header-height-small);
        }
      }
      
      @media screen and (max-width: 800px) {
  
        .elger-contact {
          height: calc(100vh + 100px)!important;
          overflow: auto!important;
        }
  
          .first-box{
            width: 100vw!important;
            height: 500px!important;
  
  
          }
        
      
        .contact-map {
          .leaflet-popup {
            z-index: 10000!important;
          }
  
          .leaflet-top.leaflet-left{
            left: 0px!important;
            top: 500px!important;
          }
  
        }
      }
      
      @media screen and (max-width: 480px) {
        height: calc(120vh - $page-title-mobile)!important;
  
        .first-box{
          height: 460px!important;
          padding: 5px max(6vw, 20px) 5px max(8vw, 20px)!important;
        }
  
        .contact-data {
          font-size: 17px!important;
        }
      
    
      .contact-map {
        padding: 0px!important;

        .leaflet-top.leaflet-left{
          left: 0px!important;
          top: 460px!important;
        }
      }

      }
    }

    .elger-recomendations {
      width: 100%;
      height: auto;
      padding: 0px;

      .recomendations-text {
        max-width: 100%; 
        height: 100%;
        margin: 40px auto; 
        text-align: center; 
        line-height: 1.4; 
        font-size: 1.2rem; 
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
      
        span {
          width: 65%; 
          display: block;
          margin-bottom: 10px; 
          font-size: 1.2rem;
          font-weight: 500;
          text-align: center
        }
      }
      
      @media (max-width: 780px) {
        .recomendations-text {
          font-size: 0.8rem;  

          span{
            width: 90%; 
            text-align: justify
          }

        }
      }

      @media (max-width: 720px) {
        .recomendations-text {
          margin: 20px auto; 
        }
      }
      
      .recomendations-bottom{
          padding: 5px 10px;
          width: 100%;
          font-weight: 600; 
          color: rgba(53, 57, 68, 0.877);
          font-size: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;

          p{
            width: 65%!important;
            text-align: center;

            @media (max-width: 780px) {
              width: 90%!important;
              margin-bottom: 20px;
            }
          }
      }
    
      .slider-container {
        width: 100%;
        margin: 40px 0px 100px 0px!important;
        height: inherit!important;

        @media (max-width: 720px) {
          margin: 20px 0px 80px 0px!important;
        }

        .swiper {
          padding-top: 36px;
          padding-bottom: 20px;
          overflow: visible;
          background-color: #3b3b3b7a;
          
          .swiper-button-next, .swiper-button-prev {
            display: none;
          }

          .swiper-pagination{
            top: 110%!important;
          }
          .swiper-pagination-bullet {
            background: rgba(0, 0, 0, 0.5);

            &.swiper-pagination-bullet-active {
              background: #1f3036dc;
            }
          }
        }

        .swiper-slide{
          display: flex;
          justify-content: center;
        }
        
        .slider-item {
          position: relative;
          overflow: hidden;
          transition: transform 0.3s ease-in-out;
          width: 200px;
          height: 300px;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 2px;
          }
          &:hover {
            transform: scale(1.05);
            cursor: zoom-in;

            .item-caption, .item-caption p{
              cursor: zoom-in;
            }


          }
          .item-caption {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            transition: height 0.3s ease, opacity 0.3s ease; /* Animacja zwijania */
        
            p {
              width: 104%;
              padding: 18px 0px;
              text-align: center;
              margin: 0;
              font-size: 16px;
              font-weight: bold;
              background-color: rgba(30, 31, 31, 0.863);
            }
    
          }
        }
      }
      
    }

    .elger-realizations {
      width: 100%;
      margin: 0 auto; 
      height: auto;

      .realizations-swiper{
          height: auto;
       
        .name{
          display: none;
        }

        .swiper {
          width: 100%;
          padding-top: 50px;
          padding-bottom: 50px;
        }
        
        .swiper-slide {
          background-position: center;
          background-size: cover;
          width: 300px;
          height: 300px;
        }
        
        .swiper-slide img {
          display: block;
          width: 100%;
          height: 80%;
        }

        .swiper-slide-active{
          .name{
            display: block;
            font-size: 1.1rem;
            padding: 10px;
            color: $black;
            max-width: 400px;
            text-align: center;
            font-weight: 600;
          }
        }

        .swiper-pagination-bullet-active{
          background: #3b5c69dc;
        }

        

      }


      .realizations {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #e6e6e65e;
        margin-top: 30px;
        padding-top: 30px;

        .realizations-title{
          width: 100%;
          height: 60px;
          margin-bottom: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          p{
            width: 100%;
            text-align: center;
            font-size: 1.1rem;
            color: $green;
            font-weight: 600;
          }

        }

        .filters {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          margin-bottom: 20px;
          width: 100vh;
        }

        .buttons{
          width: 60vw;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 30px;

          @media (max-width: 990px) {
            width: 90vw;
          }

          @media (max-width: 560px) {
            width: 80vw;
            flex-direction: column;
            gap: 10px;
          }
        }
        
        .filters {
          display: flex;
          gap: 10px;
          justify-content: center;
          margin-bottom: 20px;
        
          button {
            width: 100%;
            min-width: 120px;
            padding: 10px 20px;
            cursor: pointer;
            border: none;
            font-size: 1.05rem;
            font-weight: 500;
            border-radius: 25px;
            background: linear-gradient(135deg, #1d2b46, #3a567c); 
            color: white;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
            transition: all 0.3s ease; 
        
            &:hover {
              background: linear-gradient(135deg, #3a567c, #1d2b46); 
              transform: scale(1.05); 
              font-size: 1.1rem;
              box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); 
            }
        
            &:active {
              transform: scale(0.95);
              box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            }
        
            &.all {
              background: linear-gradient(135deg, #2c2c2c, #2c2c2cbe)
            }
        
            &.home {
              background: linear-gradient(135deg, #5480a5, #5481a5c2);
            }
        
            &.office {
              background: linear-gradient(135deg, #c52b33, #c52b33cb);
            }
          }
        }
        
        
        .filters input {
          flex: 1;
          padding: 10px;
          width: 60vw;
          border: 1px solid #c2c2c227;
          padding: 16px 20px;
          border-radius: 25px;
          font-size: 16px;


          @media (max-width: 990px) {
            width: 80vw;
          }
        }

        .table-container {
          margin: 10px auto;
          width: 100%;
          max-width: 1200px;
          font-family: Arial, sans-serif;
        }

        .no-data-message{
          padding-bottom: 20px;
        }
        
        .styled-table {
          width: 90%;
          border-collapse: collapse;
          margin: 0 auto;
          font-size: 16px;
          text-align: left;
        }
        
        .styled-table thead tr {
          text-align: left;
          font-weight: bold;
          display: none;

          th{
            color: $main-blue;
            border-bottom: 1px solid #2b3847c2;
          }
          
        }

        .styled-table td.localization {
          text-align: right; 
        }

        .styled-table td.building {
          font-weight: bold;
        }

       
        
        
        .styled-table th,
        .styled-table td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          color: $black;
          font-weight: 500;
          font-size: 1.1rem;
        }
        
        .styled-table tbody tr:nth-child(even) {
          background-color: #f3f3f3;
        }
        
        .styled-table tbody tr:hover {
          background-color: #f1f1f1;
          cursor: pointer;
        }
        
      }
    }

    .elger-work {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      height: auto;

      @media(max-width: 890px){
        gap: 10px;
        align-items: space-between;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 10px;
        max-width: 70vh;
      }

      @media(max-width: 500px){
        margin-top: 0px;
      }
      
      .work-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 50px;

        @media(max-width: 890px){
          gap: 10px;
          flex-direction: column;
        }

        img.mobile{
          display: none;

          @media(max-width: 890px){
              display: block!important;
              width: 50vh;
              filter: brightness(80%);
          }

          @media(max-width: 500px){
            display: none!important;
        }
        }

        img {
          width: 335px;
          height: auto;
          max-width: 100%;
          height: auto;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
          filter: brightness(80%);

          @media(max-width: 890px){
            display: none!important;
        }

          
        }

        .text-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-width: 450px;
          margin-top: 14px;

          @media(max-width: 890px){
            text-align: center;
            padding: 0px 20px;
        }

          @media(max-width: 500px){
            margin-top: 12px;
        }

          p {
            line-height: 1.6;
            color: $black;
            font-size: 18px;

            
          @media(max-width: 500px){
            font-size: 20px;
        }


            a{
              text-decoration: none;
              color: $green;
              font-weight: 600;
            }
          }

          .grid {
            display: grid;
            grid-template-columns: repeat(2, minmax(150px, 1fr));
            gap: 20px;
            margin: 20px 0;

            .box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 10px;
              background: white;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
              transition: transform 0.3s, box-shadow 0.3s;

              &:hover {
                transform: translateY(-5px);
                box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
              }

              span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                margin-bottom: 10px;
                border-radius: 50%;
                background-color: rgba(127, 147, 185, 0.103);
              }

              p {
                width: 120px;
                text-align: center;
                margin: 0;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.4;
                color: #0d3a86;
              }
            }
          }
        }
      }
    }

    }



footer.elger-footer {
  position: relative;
  z-index: -1;
  bottom: 0;
  z-index: 0;
  height: $footer;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.089);
  color: $black;
  padding: 10px 0px;
  display: flex;
  justify-content: center;

  .footer-content {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

    .info {
      height: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .map {
      }

      // .line{
      //   height: 80%;
      //   width: 1px;
      //   background-color: #ddd;
      // }

      .contact {
      }
    }

    p {
      font-size: 11px;
    }
  }
}
